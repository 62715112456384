import React from "react";
import { Box, Heading, Text, VStack, HStack, Tag } from "@chakra-ui/react";

function Profile() {
    return (
        <Box p={8} maxWidth="800px" margin="auto">
            <VStack spacing={6} align="stretch">
                <Heading as="h1" size="2xl" textAlign="center" color="pink.500">
                    Perfil de Senorita
                </Heading>
                
                <Box borderWidth={1} borderRadius="lg" p={6}>
                    <VStack spacing={4} align="stretch">
                        <HStack justifyContent="space-between">
                            <Heading as="h2" size="xl">
                                Isabella
                            </Heading>
                            <Text fontSize="xl" color="gray.500">
                                25 años
                            </Text>
                        </HStack>
                        
                        <Text fontSize="lg">
                            Hola, soy Isabella. Me encanta la vida y busco nuevas aventuras cada día. Soy apasionada, divertida y siempre estoy lista para una buena conversación.
                        </Text>
                        
                        <Box>
                            <Heading as="h3" size="md" mb={2}>
                                Intereses
                            </Heading>
                            <HStack spacing={2}>
                                <Tag colorScheme="pink">Baile</Tag>
                                <Tag colorScheme="purple">Cocina</Tag>
                                <Tag colorScheme="blue">Viajes</Tag>
                                <Tag colorScheme="green">Yoga</Tag>
                            </HStack>
                        </Box>
                        
                        <Box>
                            <Heading as="h3" size="md" mb={2}>
                                Personalidad
                            </Heading>
                            <Text>
                                Soy extrovertida, creativa y siempre busco el lado positivo de las cosas. Me encanta reír y hacer reír a los demás.
                            </Text>
                        </Box>
                        
                        <Box>
                            <Heading as="h3" size="md" mb={2}>
                                Buscando
                            </Heading>
                            <Text>
                                Alguien con quien compartir risas, aventuras y momentos especiales. ¿Serás tú?
                            </Text>
                        </Box>
                    </VStack>
                </Box>
            </VStack>
        </Box>
    );
}

export default Profile;
