import { Box, Heading, Text, UnorderedList, ListItem } from '@chakra-ui/react';

function Policy() {
    return (
        <Box p={4}>
            <Heading as="h1" size="xl" mb={4}>Política de Privacidad</Heading>
            <Text mb={4}>Última actualización: 29 de agosto de 2024</Text>
            <Text mb={4}>
                Esta Política de Privacidad describe nuestras políticas y procedimientos sobre la recopilación, uso y divulgación de su información cuando utiliza nuestro Servicio. También explica sus derechos de privacidad y cómo la ley lo protege.
            </Text>
            <Text mb={4}>
                Estamos comprometidos a proteger su privacidad. Solo utilizamos sus Datos Personales para proporcionar y mejorar el Servicio. Al utilizar el Servicio, usted acepta la recopilación y el uso de información de acuerdo con esta Política de Privacidad.
            </Text>
            <Heading as="h2" size="lg" mt={6} mb={4}>Principios Clave:</Heading>
            <UnorderedList mb={4}>
                <ListItem><strong>Sin Compartir Información:</strong> No compartimos su información con terceros. Sus datos se utilizan únicamente de forma interna para mejorar la experiencia del usuario y la calidad del Servicio.</ListItem>
                <ListItem><strong>Pagos Transparentes:</strong> Cualquier pago que realice a través de nuestro Servicio no se etiquetará como relacionado con transacciones para adultos en sus estados de cuenta bancarios. No hay tarifas ocultas asociadas con nuestro Servicio.</ListItem>
            </UnorderedList>
            <Heading as="h2" size="lg" mt={6} mb={4}>Interpretación y Definiciones</Heading>
            <Heading as="h3" size="md" mt={4} mb={2}>Interpretación</Heading>
            <Text mb={4}>
                Las palabras cuyas iniciales están en mayúscula tienen significados específicos definidos a continuación. Estas definiciones se aplican de manera consistente, ya sea que aparezcan en singular o en plural.
            </Text>
            <Heading as="h3" size="md" mt={4} mb={2}>Definiciones</Heading>
            <UnorderedList mb={4}>
                <ListItem><strong>Cuenta:</strong> Una cuenta única creada para que usted acceda a nuestro Servicio o partes de nuestro Servicio.</ListItem>
                <ListItem><strong>Compañía</strong> (referida como "la Compañía", "Nosotros", "Nos" o "Nuestro" en este Acuerdo): Se refiere a Cohuman, 1541 Oxford Street, Berkeley, CA 94709.</ListItem>
                <ListItem><strong>Servicio:</strong> Se refiere al Sitio Web.</ListItem>
                <ListItem><strong>Sitio Web:</strong> Se refiere a WhatGF, accesible desde <a href="https://whatgf.com/" rel="external nofollow noopener" target="_blank">https://whatgf.com/</a></ListItem>
                <ListItem><strong>Usted:</strong> Se refiere al individuo que accede o utiliza el Servicio, o la compañía u otra entidad legal en nombre de la cual dicho individuo está accediendo o utilizando el Servicio, según corresponda.</ListItem>
            </UnorderedList>
            <Text mt={4}>
                Si tiene alguna pregunta sobre esta Política de Privacidad, puede contactarnos por correo electrónico a: cohuman.gang@gmail.com
            </Text>
        </Box>
    );
}

export default Policy;
