import React from "react";
import { Box, Heading, Text, VStack, Button, useColorModeValue } from "@chakra-ui/react";

function Roleplay() {
    const bgColor = useColorModeValue("pink.50", "gray.800");
    const textColor = useColorModeValue("gray.800", "white");

    return (
        <Box bg={bgColor} minHeight="100vh" py={10}>
            <VStack spacing={8} align="center">
                <Heading as="h1" size="2xl" color="pink.500">
                    Juego de Roles Seductores
                </Heading>
                <Text fontSize="xl" color={textColor} textAlign="center" maxWidth="800px" px={4}>
                    Sumérgete en un mundo de fantasía y seducción. Explora diferentes escenarios y personajes para vivir experiencias únicas y apasionantes.
                </Text>
                <Box>
                    <Heading as="h2" size="lg" color={textColor} mb={4}>
                        Escenarios Disponibles:
                    </Heading>
                    <VStack spacing={4} align="stretch">
                        <Text fontSize="md" color={textColor}>• La Cita Perfecta</Text>
                        <Text fontSize="md" color={textColor}>• Encuentro en la Playa</Text>
                        <Text fontSize="md" color={textColor}>• Noche de Pasión en París</Text>
                        <Text fontSize="md" color={textColor}>• Seducción en la Oficina</Text>
                    </VStack>
                </Box>
                <Button 
                    colorScheme="pink" 
                    size="lg"
                    _hover={{ bg: "pink.600" }}
                >
                    Comenzar Juego de Roles
                </Button>
                <Text fontSize="sm" color={textColor} textAlign="center" maxWidth="600px" px={4}>
                    Recuerda: Nuestros juegos de roles son para adultos y requieren consentimiento mutuo. Disfruta de manera responsable y respetuosa.
                </Text>
            </VStack>
        </Box>
    );
}

export default Roleplay;