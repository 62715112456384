import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './components/Home';
import Chat from './components/Chat';
import Roleplay from './components/Roleplay';
import Profile from './components/Profile';
import Policy from './components/Policy';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/chat" element={<Chat />} />
        <Route path="/roleplay" element={<Roleplay />} />
        <Route path="/profile/:username" element={<Profile />} />
        <Route path="/privacy-policy" element={<Policy />} />
      </Routes>
    </Router>
  );
}

export default App;