import React from "react";

function Chat() {
    return (
        <div className="chat-container">
            <h1>Chicas Sensuales</h1>
            <p>Bienvenido al mundo de las chicas más hermosas y seductoras.</p>
            <div className="girl-gallery">
                <div className="girl-card">
                    <h2>Sofía</h2>
                    <p>Ardiente y apasionada</p>
                    <p>Edad: 24 años</p>
                    <p>Intereses: Baile, cocina exótica, yoga</p>
                </div>
                <div className="girl-card">
                    <h2>Valentina</h2>
                    <p>Misteriosa y seductora</p>
                    <p>Edad: 26 años</p>
                    <p>Intereses: Literatura erótica, vino, viajes</p>
                </div>
                <div className="girl-card">
                    <h2>Isabella</h2>
                    <p>Dulce y picante</p>
                    <p>Edad: 23 años</p>
                    <p>Intereses: Fotografía, música, moda</p>
                </div>
            </div>
            <button className="cta-button">Chatea ahora con nuestras chicas</button>
        </div>
    );
}

export default Chat;