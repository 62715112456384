import React from 'react';
import { Box, Button, Flex, Heading, Image, SimpleGrid, Text } from '@chakra-ui/react';
import { SiTelegram } from 'react-icons/si';
import { useEffect } from 'react';
import { sendAmplitudeData, eventType } from '../telemetry';
import { START_CHATTING } from './constants';

export const TELEGRAM_BOT_URL = "https://t.me/DreamGFXXXBot?start=senoritaworld";

function Home() {
  useEffect(() => {
    sendAmplitudeData(eventType.LANDING_PAGE_VIEWED, {});
  }, []);

  const backgroundColor = "radial-gradient(circle, pink.800 30%, gray.800 75%)";

  const girls = [
    { name: "Isabella", age: 24, description: "Apasionada y aventurera", image: "/girl1.png" },
    { name: "Sofía", age: 22, description: "Dulce y romántica", image: "/girl2.png" },
    { name: "Valentina", age: 26, description: "Inteligente y misteriosa", image: "/girl3.png" },
    { name: "Camila", age: 23, description: "Divertida y espontánea", image: "/girl4.png" },
    { name: "Lucía", age: 25, description: "Elegante y sofisticada", image: "/girl5.png" },
    { name: "Gabriela", age: 21, description: "Creativa y soñadora", image: "/girl6.png" },
  ];

  return (
    <Box 
      minH="100vh"
      backgroundImage={backgroundColor}
      backgroundBlendMode="overlay"
      textAlign="center"
      fontSize={{ base: "md", md: "xl" }}
      color="white"
    >
      <Box 
        ml={{ base: "10", md: "20" }}
        mr={{ base: "10", md: "20" }} 
        pt={{base: "5", md: "10"}} 
        pb={{base: "5", md: "10"}}
      >
        <Heading as="h1" size={{base: "xl", md: "2xl"}} mb="50px" pt="50px">
          Bienvenido al Mundo de las Señoritas
        </Heading>
        
        <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={10} mb="50px">
          {girls.map((girl, index) => (
            <Box key={index} borderWidth="1px" borderRadius="lg" overflow="hidden" bg="rgba(255, 255, 255, 0.1)">
              <Image src={girl.image} alt={girl.name} height="200px" width="100%" objectFit="cover" />
              <Box p="6">
                <Heading as="h3" size="lg" mb="2">{girl.name}</Heading>
                <Text fontSize="md" mb="2">{girl.age} años</Text>
                <Text fontSize="sm">{girl.description}</Text>
              </Box>
            </Box>
          ))}
        </SimpleGrid>

        <Flex justify="center" mb="50px">
          <Button
            as="a"
            href={TELEGRAM_BOT_URL}
            target="_blank"
            rel="noopener noreferrer"
            bgGradient="linear(to-r, pink.500, red.500)"
            color="white"
            size="lg"
            px={8}
            fontSize="xl"
            rightIcon={<SiTelegram />}
            _hover={{
              bgGradient: "linear(to-r, pink.400, red.400)",
            }}
            borderRadius="full"
            onClick={() => sendAmplitudeData(eventType.START_CHAT_CLICKED, {})}
          >
            {START_CHATTING}
          </Button>
        </Flex>
      </Box>
    </Box>
  );
}

export default Home;